import React from 'react';
import {
  Link
} from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';
import AlbumsMenu from './AlbumMenuComp';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width:'100%',
    
  }, 
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const NavListItemText = withStyles({
    root: {
      maxWidth:'max-content',
      borderBottom: "solid 3px transparent",
      textTransform:'capitalize',
      "&:hover": {
       borderBottomColor: "#c3922e"
      }
    }
  })(ListItemText);

  const NavListItem = withStyles({
    root: {
      maxWidth:'max-content'
    }
  })(ListItem);


export default function MenuListComposition() {
  const classes = useStyles();
return (
    <nav className={classes.root}>
     <List  className="nav-list-item-text">
          <NavListItem  button >
            <Link to={'/'} ><NavListItemText  primary={'startseite'} /></Link>
          </NavListItem>
          <AlbumsMenu/>
          <NavListItem  button >
            <Link to={'/ueber'} ><NavListItemText  primary={'über'} /></Link>
          </NavListItem>
        </List>
      
    </nav>
  );
}
