import React from 'react';
import {
  Link
} from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';
import {useQuery, gql}from '@apollo/client';



const QUERY =gql`
query AlbumNames{
    albumCollection{
     items{
       albumName
     
     }
   }
   }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft:'10px'
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const NavListItemText = withStyles({
    root: {
      
      maxWidth:'max-content',
      textTransform:'capitalize',
      borderBottom: "solid 3px transparent",
      "&:hover": {
        borderBottomColor: "#c3922e"
      },"&:active":{
        color:"#c3922e"
      }
    }
  })(ListItemText);
  const NavListItem = withStyles({
    root: {
      maxWidth:'max-content'
    }
  })(ListItem);
  

export default function MenuListComposition(props) {
  const classes = useStyles();
 const {data, errors, loading}= useQuery(QUERY);
 
return (
    <nav className={classes.root}>
      {errors && <span>{errors}</span>}
     <List className="nav-Albumslist-item-text">
          {loading ? (<h4>Loading...</h4>):(data.albumCollection.items.slice(0).reverse().map((item, index) => 
          <NavListItem button onClick={props.handelSeriesList} key={index}>
            <Link to={'/'+item.albumName}> <NavListItemText  primary={item.albumName} /></Link>
          </NavListItem>
       ))}
        </List>
      
    </nav>
  );
}
