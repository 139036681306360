import React from "react";
import {
  Link
} from "react-router-dom";
import logoPic from "./luisa-app-logo.png";
import useWindowSize from "../useWindowSize";
import BurgerMenu from "./DrwerMenu.js";
import "./navStyles.css";
import NavMenu from './NavMenu';
import MailRoundedIcon from '@material-ui/icons/MailRounded';

const NavBar = ({ data }) => {
  const sizes = useWindowSize();

  return (
 
      <div className="navbar">
       <div className="nav-list-con" >
         <Link to="/"><img src={logoPic} alt="logo foto" /> </Link>
         <p>Fotografie</p>
         </div>
         <div className="nav-list-items">
        {sizes.width <= 870 ? <BurgerMenu /> : <NavMenu />}
        </div>
        {sizes.width <= 870 ? null :  <div className="contactMe-container" ><p>Portfolio auf Anfrage</p><a href= "mailto:weinluisa@googelmail.com" style={{height:"20px"}}><MailRoundedIcon style={{ color:"#c3922e"}}/></a></div>}
        { sizes.width <= 870 ? null :<div className="copyRightCon"><span>© Copyright {new Date().getUTCFullYear()}</span></div> }
         
         
      </div>
  );
};

export default NavBar;
