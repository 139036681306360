/* eslint-disable react/prop-types */
import React,{useState} from "react";
import {Link,
  Switch,
  Route
} from "react-router-dom";
import StackGrid, { transitions, easings } from "react-stack-grid";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useWindowSize from "./useWindowSize";
import "../../src/App.css";
import ImageViewBox from './ImageBox';


const transition = transitions.scaleDown;


const RealWorld = (props) => {


  const sizes = useWindowSize();

 
  const [imageIndex, setImageIndex]=useState(1);
  

  const openViewBox=(event)=>{
              setImageIndex(props.albumData.fotosLinkCollection.items.findIndex(x => x.url === event.target.src));
             
  }
  return (
    <>
    <Switch>
    
    <Route path={`/${props.albumData.albumName}`} exact>
   <StackGrid
   className="stack-grid-image" 
      monitorImagesLoaded
      columnWidth={sizes.width <= 870 ? "100%" :"25%"}
      duration={240}
      gutterWidth={20}
      gutterHeight={20}
      easing={easings.circOut}
      appearDelay={60}
      appear={transition.appear}
      appeared={transition.appeared}
      enter={transition.enter}
      entered={transition.entered}
      leaved={transition.fadeDown}>
      {props.albumData.fotosLinkCollection.items.map((obj,index) => (
        <Link to={`/${props.albumData.albumName}/${obj.fileName}`} key={index} >
      <LazyLoadImage className="image" visibleByDefault={obj.url} onClick={openViewBox} src={obj.url} alt={obj.description} /></Link>
      ))}
    </StackGrid>
    </Route>
   {props.albumData.fotosLinkCollection.items.map((item,index)=>(

      <Route path={`/${props.albumData.albumName}/${item.fileName}`} key={index} >
       <ImageViewBox images={props.albumData.fotosLinkCollection.items} albumName={props.albumData.albumName} imageUrl={item.url} imageIndex={imageIndex} />
      </Route>

   ))}
   
    </Switch>
    </>
  );
};
export default RealWorld;
