import "./App.css";
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import NavBar from "./components/naveBar/NavBar";
import Gallery from "./components/Gallery";
import HomePage from './components/HomePage';
import AboutMePage from './components/AboutMe';
import useWindowSize from './components/useWindowSize';


import {useQuery, gql}from '@apollo/client'
const QUERY =gql`
query Albums{
 albumCollection{
  items{
    albumName
    albumDescription
    fotosLinkCollection{
      items{url
        fileName
      description
      width
      height
      }
    }
  }
}
}

`;



function App() {
 const windowSize= useWindowSize();
   const {data, errors ,loading}= useQuery(QUERY);
   
   const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding:0,
      backgroundColor:"inherit",
      height:"100vh",
      display:windowSize.width <= 870 ? null : "flex",
    },gridItem: {
      padding: 0,
      margin:windowSize.width<= 870 ? "1em" : '0 auto',
      transform:windowSize.width<= 870 ? "translateY(25px)" : null,
      
    },homePagePaper:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: "center",
      width:'inherit',
      height:windowSize.width <= 870 ? "fit-content" : "100vh",
      color:'#47524b',
      backgroundColor:"inherit",
      boxShadow:"none"

    },
    gridChild: {
      textAlign: "center",
      width:'inherit',
      padding:'1em 0em',
      height:windowSize.width <= 870 ? "fit-content" : "100vh",
      color:'#47524b',
      backgroundColor:"inherit",
      boxShadow:"none"
    },
    gridChildGallery:{
      textAlign: "center",
      width:'inherit',
      padding:'5%',
      height:windowSize.width <= 870 ? "fit-content" : "100vh",
      color:'#47524b',
      backgroundColor:"inherit",
      boxShadow:"none"
    },
    gridNav: {
      display:'flex',
      alignItems:'center',
      maxWidth:windowSize.width <= 870 ? "100%" : "100%",
      height:windowSize.width <= 870 ? "fit-content" : "100vh",
      backgroundColor:"transparent",
      color:"inherit",
      borderRadius:0,
      boxShadow:"none",
    },fixedPosition:{
      zIndex:2,
      
    }
  }));
  const classes = useStyles();


  if (errors) return `Error! ${errors.message}`;
  return (
    <Router>
    <div className={classes.root}>
			<Grid
				container
				direction= "row"
				justify={windowSize.width<=870?"center":"space-between"}
				
				spacing={0}>
          <Grid className={classes.gridItem}  item xs={12} md={2}>
          <Paper className={classes.gridNav}><NavBar data={null} /></Paper> 
          </Grid>
        

        <Switch >
          <>
            <Route path='/'exact >	
              <Grid className={classes.gridItem} item xs={12} md={10}>
                <Paper className={classes.homePagePaper}>	<HomePage /></Paper> 
              </Grid>
            </Route>
            <Route path='/ueber' >
              <Grid className={classes.gridItem} item xs={12} md={10}>
                <Paper className={classes.gridChild}>	<AboutMePage /></Paper> 
              </Grid>
            </Route>
            {loading ? (<h5>Loading...</h5>):(data.albumCollection.items.map((albumItem)=>(
              <Route path={'/'+albumItem.albumName} key={albumItem.albumName}>	
                <Grid className={classes.gridItem} item xs={windowSize.width <= 870 ? 11 : 10}>
                  <Paper className={classes.gridChildGallery}>
                    <Gallery  albumData={albumItem}/>
                  </Paper> 
                </Grid>
              </Route>
            )))}
          </>
        </Switch>
      </Grid>
		</div>
    
  </Router>
  );
}

export default App;