/* eslint-disable react/prop-types */
import React from "react";
import "./style.css";


import {useQuery, gql}from '@apollo/client'
import Slider from './HomePageSlider'

const QUERY =gql`
query  HomePageImages{
  landingPageCollection{
    items{
        homePageDescription
        homePageImageCollection{
            items{
                url
                description
                title
                width
                height
            }
        }
    }
  }
}

`;


const Index = () => {

const {data, errors, loading}= useQuery(QUERY);



  if (loading) return 'Loading...';
  if (errors) return `Error! ${errors.message}`;

  return (
      
    <div className="home-page-con">
      {loading ?(<h5>Loading...</h5>):(
      
      < Slider loading={loading}items={data.landingPageCollection.items[0].homePageImageCollection.items} />
      
     )}

     {/* {data.landingPageCollection.items.map((item,index)=>( <div className="contactMe-container" key={index}> <p>{item.homePageDescription}</p><a href= "mailto:weinluisa@googelmail.com" style={{height:"20px"}}><MailRoundedIcon style={{ color:"#c3922e"}}/></a></div> ))} */}
    
     

     </div>
  )
}
export default Index;
