/* eslint-disable react/prop-types */
import React,{useState}from "react";
import Carousel from 'react-material-ui-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "./style.css";




const Index = (props) => {


const [images]=useState(props.items);

  return (
      
    <div className="slide" >
      {props.loading ?(<h5>Loading...</h5>):(
      
       <Carousel indicators={false} swipe={true} navButtonsAlwaysInvisible={true} autoPlay={true}  >
                {images.map((item, index) => (
                 <div key={index}>
                 <LazyLoadImage   visibleByDefault={item.url} src ={item.url} alt={item.description}  /> 
                 </div>

                ))}
             </Carousel>
            
            )}
         
  </div>
  )
}
export default Index;
