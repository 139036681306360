import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import {
    Link
  } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';
import {useQuery, gql}from '@apollo/client';

const QUERY =gql`
query AlbumNames{
    albumCollection{
     items{
       albumName
     
     }
   }
   }
`;


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      paddingt:'0px'
    },
    paper: {
      marginRight: theme.spacing(0),
    },
  }));
  
  const NavListItemText = withStyles({
      root: {
        
        maxWidth:'max-content',
        textTransform:'capitalize',
        borderBottom: "solid 3px transparent",
        "&:hover": {
          borderBottomColor: "#c3922e"
        },"&:active":{
          color:"#c3922e"
        }
      }
    })(ListItemText);
    const NavListItem = withStyles({
      root: {
        maxWidth:'max-content',
        cursor:"pointer"
      }
    })(ListItem);

export default function SimpleMenu() {
    const classes = useStyles();
    const {data, errors, loading}= useQuery(QUERY);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <div className={classes.root}>
      <NavListItem button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <NavListItemText primary={'serien'} />
      </NavListItem> 
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         {errors && <span>{errors}</span>}
         {loading ? (<h4>Loading...</h4>):(data.albumCollection.items.slice(0).reverse().map((item, index) => 
          <NavListItem button onClick={handleClose} key={index}>
            <Link to={'/'+item.albumName}> <NavListItemText  primary={item.albumName} /></Link>
          </NavListItem>))}
      </Menu>
    </div>
  );
}