
import React from "react";
import "./style.css";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Index = (props) => {
const images=props.images;

const [wantedImage, setWantedImage]=React.useState(props.imageIndex);
const nextImage=()=>{
    if(wantedImage < images.length ){
        setWantedImage(wantedImage + 1);
    }

}
const prevImage=()=>{
    if(wantedImage < images.length && wantedImage > 0){
        setWantedImage(wantedImage - 1);
    }
}

  return (
    <div className="image-view-box-con">
    <div className="img-con">
       <LazyLoadImage  visibleByDefault={images[wantedImage].url} className="inside-image" src={images[wantedImage].url} alt="images" /> 
    </div>
     <div className="gallery-nav-con">
     {wantedImage < images.length && wantedImage > 0 ? <ChevronLeftIcon className="prev-btn" onClick={prevImage} />:null}
     <Link to={`/${props.albumName}`}>
      <CloseIcon className="close-btn" />
      </Link>
     {wantedImage < images.length-1 ? <ChevronRightIcon className="next-btn" onClick={nextImage} />:null}
     
     </div>
    
   </div>
  );
};
export default Index;
