import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {ApolloProvider, ApolloClient, InMemoryCache}from '@apollo/client'



const client = new ApolloClient({
  cache: new InMemoryCache(),
 uri:'https://graphql.contentful.com/content/v1/spaces/pgeue95yjva3',
 headers:{
   'Authorization': `Bearer Avn6hPtNNj5nRm6IQQpGr-Fc2Ns_SLE-Fy4YRrtu5ac`,
 },
})
ReactDOM.render(
  
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  ,
  document.getElementById('root')
);
