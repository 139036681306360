import React from "react";
import {
  Link
} from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import AlbumsNavList from './AlbumsNavList';
import MailRoundedIcon from '@material-ui/icons/MailRounded';


const useStyles = makeStyles({
    list: {
      width: 150,
      fontSize:19,
      backgroundColor:'#e8e5e5',
    },
    fullList: {
      width: "auto"
    }
  });
  const NavListItemText = withStyles({
    root: {
      maxWidth:'max-content',
      borderBottom: "solid 3px transparent",
      textTransform:'capitalize',
      alignItems:'center',
      display:'flex',
      "&:hover": {
       borderColor: "#c3922e"
      }
    }
      
    
  })(ListItemText);
  const NavListItem = withStyles({
    root: {
      maxWidth:'max-content'
    }
  })(ListItem);
  
  
  
  export default function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <nav
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom"
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List  className="nav-list-item-text">
          {["startseite", "serien", "über"].map( (text, index) => { return  text === 'serien' ?  <div key={index} > <NavListItem button  >
            <NavListItemText  primary={text} />
            </NavListItem> 
           <AlbumsNavList /> 
            </div>:( text === 'startseite' ? <NavListItem button key={index}>
             <Link to={'/'} ><NavListItemText  primary={text} /></Link>
            </NavListItem>: <NavListItem button key={index}>
             <Link to={'/ueber'} ><NavListItemText  primary={text} /></Link>
            </NavListItem>)
            }
          )}
          <NavListItem  >
             <NavListItemText>
             <div className="contactMe-container d-row"><p>Portfolio auf Anfrage</p><a href= "mailto:weinluisa@googelmail.com" style={{height:"20px"}}><MailRoundedIcon style={{ color:"#c3922e"}}/></a></div>
             </NavListItemText>
            </NavListItem>
           
        
        </List>
      </nav>
    );
  
    return (
      <div  style={{width:"45px"}}>
        {["top"].map((anchor) => (
          <React.Fragment key={anchor} >
            <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    );
  }
  