/* eslint-disable react/prop-types */
import React from "react";
import "./style.css";
import {useQuery, gql}from '@apollo/client'

const QUERY =gql`
query  AboutMePage{
    aboutCollection{
    items{
      aboutMe
      aboutMeText
    }
  }
}

`;


const Index = () => {

const {data, errors, loading}= useQuery(QUERY);



  if (loading) return 'Loading...';
  if (errors) return `Error! ${errors.message}`;

  return (
      
    <>
      {loading ?(<h5>Loading...</h5>):(<div className='aboutMe-page-con' >
      
      {data.aboutCollection.items.map((item,index)=><span key={index}>{item.aboutMeText}</span>)}
     </div>)
}
  </>
  )
}
export default Index;
